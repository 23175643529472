import restaurant from "./images/RESTAURANT.jpg";
import img_card from "./images/n2.jpg";
import imgheaderrestaurant from "./images/ringroz.jpg";
import psrestaurant from "./images/m2.jpeg";
import imgheader from "./images/b2.jpg";
import imgheaderm from "./images/b2.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";
import icon from "./images/porumbel.png";


const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Andrei",
        mireasa: "Inga",
        data: "7 Iunie 2024",
        data_confirmare: "15 mai 2024",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "inga.capmaru@gmail.com", 
       tel: "+373 692 78 160",
       phone: "tel:+37369278160",
       viber: "viber://chat?number=%2B37369278160",
       whatsapp: "https://wa.me/+37369278160",
       messenger: "https://www.messenger.com/t/inga.kapmaru",
       tel1: "+37360308216",
       phone1: "tel:+37360308216",
       viber1: "viber://chat?number=%2B37360308216",
       whatsapp1: "https://wa.me/+37360308216",
       messenger1: "https://www.messenger.com/t/100002166075090",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Richland",
            name1: "Sala DIAMANT",
            data: "7 iunie 2024, vineri, ora 17:00",
            adress: "Grătiești, MD-2093, R. Moldova",
            harta: "https://maps.app.goo.gl/dZhR8wt3EQJNUZG99",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10866.789736928076!2d28.8271568!3d47.0853967!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d1c8588b3f7%3A0x105ba631571eda8b!2sRichland!5e0!3m2!1sro!2s!4v1709381048226!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            img2: icon,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Petru și Claudia Roșca",
            parintii_nume2: "Iurie și Inga Capmaru",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Iurie și Tatiana Mocanu",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],
    blogRestaurant: [
        {

            img: psrestaurant,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
          
        }
    ],
}

export default data;